import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Stack,
  Typography,
  TextField,
  Link,
  Dialog,
  useTheme,
  Card,
  CardContent,
  Radio,
  Box,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  InvitePhoneCandidatePlayground,
  getInterviewTypesForDemoPhoneInterviews,
} from "../PhoneInterviewApi";
import { AnimatePresence, motion } from "framer-motion";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CandidateReport from "./CandidateReport";
import Header from "./Header";
import CustomPhoneInput from "../CustomPhoneInput";
import indianrecruiter from "../assets/indianrecruiter.png";
import americanrecruiter from "../assets/americanrecruiter.png";
function PhoneInterviews({
  username,
  phone,
  setVisibleInterviewOptions,
  setIsOpen,
}) {
  const [fetchStatus, setFetchStatus] = useState(false);
  // Changed it to true for testing
  const [selectedInterviewId, setSelectedInterviewId] = useState(null);
  const [selectedInterviewRegion, setSelectedInterviewRegion] =
    useState("India");
  const interviewRef = useRef(null);
  const [candidateId, setCandidateId] = useState(0);
  const [interviews, setInterviews] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [addingPhoneNumberDialog, setAddingPhoneNumberDialog] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const handlePopoverOpen = (event, description) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(description);
    setPopOverOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent("");
    setPopOverOpen(false);
  };

  useEffect(() => {
    getInterviewTypesForDemoPhoneInterviews().then((res) => {
      let india_interviews = [];
      let usa_interviews = [];
      res.map((singleinterview) => {
        if (singleinterview.region == "India") {
          india_interviews.push(singleinterview);
        } else {
          usa_interviews.push(singleinterview);
        }
      });
      setInterviews([...usa_interviews, ...india_interviews]);
    });
    setSelectedInterviewId(null);
    setSelectedInterviewRegion("India");
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      // if (
      //   interviewRef.current &&
      //   !interviewRef.current.contains(event.target)
      // ) {
      //   setSelectedInterviewId(null);
      // }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      style={{
        paddingTop: "70px",
        paddingBottom: "100px",
      }}
    >
      {/* <Stack
        // fontSize={theme.typography.h4.fontSize}
        // fontWeight={theme.typography.h4.fontWeight}
        // marginBottom={"50px"}
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        color={"white"}
      >
        <Typography variant="h6">Which role do you want to explore?</Typography>
      </Stack> */}
      <Stack
        ref={interviewRef}
        spacing={2}
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          flexWrap="wrap"
          width={{ xs: "90%", sm: "70%" }}
        >
          {interviews?.map((interview, idx) => (
            //Updated state
            <>
              {idx === 3 && (
                <Stack
                  sx={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    padding: "0px 15px",
                    width: "100%", // Ensure the heading spans the entire width
                    margin: "30px 0px 20px 5px",
                    fontSize: theme.typography.h6.fontSize,
                    fontWeight: theme.typography.h6.fontWeight,
                  }}
                >
                  <img
                    src={indianrecruiter}
                    width={"50px"}
                    style={{ borderRadius: "50%", marginRight: "10px" }}
                  />
                  Talk to Rohan, our Indian AI-Recruiter
                </Stack>
              )}

              {idx == 0 && (
                <Stack
                  sx={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    width: "100%",
                    padding: "0px 15px",
                    fontSize: theme.typography.h6.fontSize,
                    fontWeight: theme.typography.h6.fontWeight,
                    margin: "30px 0px 20px 5px",
                  }}
                >
                  <img
                    src={americanrecruiter}
                    width={"50px"}
                    style={{ borderRadius: "50%", marginRight: "10px" }}
                  />
                  Talk to Eric, our American AI-Recruiter
                </Stack>
              )}
              <Box
                key={interview.id}
                sx={{
                  width: {
                    xs: "100%",
                    md: "calc(50%)",
                    lg: "calc(50%)",
                  },

                  boxSizing: "border-box",
                  padding: "6px",
                }}
                onMouseEnter={() => setHoveredIndex(idx)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {/*<AnimatePresence>
                {hoveredIndex === idx && (
                  <motion.span
                    layoutId="hoverBackground"
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: { duration: 0.15 },
                    }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.15, delay: 0.2 },
                    }}
                  />
                )}
              </AnimatePresence>*/}

                <div
                  style={{
                    cursor: "pointer",
                    borderWidth: "0px",
                    borderStyle: "solid",
                    borderColor: "#6d6d6d",
                    backgroundColor:
                      selectedInterviewId === interview.id
                        ? "#6d6d6d"
                        : theme.palette.background.paper,

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "20px",
                    position: "relative",
                    borderRadius: "12px",
                    transform: "none",
                    minHeight: "125px",
                  }}
                  onClick={() => {
                    if (selectedInterviewId === interview.id) {
                      setSelectedInterviewId(null);
                      setSelectedInterviewRegion("India");
                    } else {
                      setSelectedInterviewId(interview.id);
                      setSelectedInterviewRegion(interview.region);
                    }
                  }}
                >
                  <CardContent
                    style={{
                      padding: "0",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "1.15rem",
                        fontWeight: theme.typography.subtitle1.fontWeight,
                        color: theme.palette.text.primary,
                      }}
                    >
                      {interview.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        marginTop: "10px",
                        color: theme.palette.text.primary,
                      }}
                    >
                      {interview.description
                        ? interview.description
                        : "No Description Provided"}
                    </Typography>
                    {/*
                  <Typography
                    variant="body2"
                    onMouseEnter={(e) => handlePopoverOpen(e, interview.description)}
                    onMouseLeave={handlePopoverClose}
                    style={{
                      marginTop: '5px',
                      color: theme.palette.text.secondary,
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      cursor: 'pointer',
                    }}
                  >
                    {interview.description}
                  </Typography>
                  */}
                  </CardContent>

                  {/* <Typography
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                > */}
                  {selectedInterviewId === interview.id ? (
                    <CheckCircleOutlinedIcon
                      fontSize="small"
                      style={{
                        // color: "white",
                        marginLeft: "15px",
                        // border: "none",
                        color: "rgb(18, 167, 151)",
                      }}
                    />
                  ) : (
                    <CircleOutlinedIcon
                      fontSize="small"
                      style={{ color: "white", marginLeft: "15px" }}
                    />
                  )}
                  {/* </Typography> */}
                  {/*<Radio
                  checked={selectedInterviewId === interview.id}
                  onChange={() => setSelectedInterviewId(interview.id)}
                  value={interview.id}
                  style={{
                    color: theme.palette.primary.main,
                    marginRight: "10px",
                  }}
                />*/}
                </div>
              </Box>
            </>
          ))}
        </Box>
        {/*<Popover
          open={popOver}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ pointerEvents: 'none' }}
          PaperProps={{
            style: { padding: '10px', maxWidth: '300px' },
          }}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>*/}
        <Stack
          sx={{
            position: "fixed",
            width: "100%",
            bottom: "0px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(18,18,18,0.7)",
            display: "flex",
            flexDirection: "row",
            backdropFilter: "blur(8px)",

            zIndex: "100",
          }}
        >
          <Button
            onClick={() => {
              setAddingPhoneNumberDialog(true);
            }}
            disabled={!selectedInterviewId}
            variant="contained"
            sx={{
              cursor: "pointer",
              bgcolor: !selectedInterviewId ? "grey.500" : "primary.main", // Grey background for disabled
              color: "white",
              "&:hover": {
                bgcolor: !selectedInterviewId ? "grey.500" : "primary.dark", // Ensure no hover effect when disabled
              },
              margin: "10px 0px",
              backdropFilter: "none",
              opacity: "1",
            }}
          >
            Start Interview
          </Button>
          <Dialog
            open={addingPhoneNumberDialog}
            onClose={() => {
              setAddingPhoneNumberDialog(false);
            }}
          >
            <DialogContent sx={{ margin: "0px 20px", padding: "15px 24px" }}>
              <Stack sx={{ marginBottom: "10px" }}>
                <Typography variant="subtitle1">
                  Enter the phone number you would like to use for the call{" "}
                </Typography>
              </Stack>
              <CustomPhoneInput
                value={phoneNumber}
                onChange={(val) => {
                  setPhoneNumber(val);
                }}
                defaultCountry={
                  selectedInterviewRegion == "India" ? "IN" : "US"
                }
                inputProps={{
                  label: "Phone Number",
                  variant: "standard",
                }}
              />
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  cursor: "pointer",
                  padding: "5px 50px",
                  marginBottom: "10px",
                }}
                onClick={() => {
                  InvitePhoneCandidatePlayground(selectedInterviewId, {
                    name: username,
                    phone_number: phoneNumber,
                  }).then((res) => {
                    setCandidateId(res.candidate_id);
                    setFetchStatus(true);
                    setAddingPhoneNumberDialog(false);
                  });
                }}
              >
                Start
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Stack>
      {fetchStatus && (
        <CandidateReport
          fetchStatus={fetchStatus}
          setFetchStatus={setFetchStatus}
          candidateId={candidateId}
          selectedInterviewRegion={selectedInterviewRegion}
        />
      )}
    </div>
  );
}

export default PhoneInterviews;
