import * as React from "react";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { Stack, Typography } from "@mui/material";
import flags from "react-phone-number-input/flags";
//import "./CustomPhoneInput.scss";
import { CountryCode } from "libphonenumber-js/min";

export default function CustomPhoneInput(props: CustomPhoneInputProps) {
  function setCountryCode(phoneNumber: string) {
    let parsedNumber = parsePhoneNumber(phoneNumber);
    if (parsedNumber && parsedNumber.countryCallingCode === "1") {
      parsedNumber.country = "US";
      return parsedNumber;
    }
  }

  return (
    <Stack sx={{ width: "100%" }} className={props.hideFlags && "hide-flags"}>
      <PhoneInput
        defaultCountry={
          setCountryCode(props.value || "")?.country ||
          props.defaultCountry ||
          "IN"
        }
        value={setCountryCode(props.value || "")?.number || props.value}
        onChange={(event) => {
          console.log(event);
          props.onChange(event);
        }}
        style={{
          marginLeft: "5px",
          borderRadius: "12px",
          boxSizing: "border-box",
          // backgroundColor: "rgba(200, 200, 200, 1)",
        }}
        numberInputProps={{
          ...props.inputProps,
          style: {
            flexGrow: 1,
            marginLeft: "12px",
            outline: "none",
            width: "100%",
            fontSize: "16px",
            padding: "10px 12px",
            borderRadius: "8px",

            // background: "rgba(220,220,220,1)",
            border:
              props.value &&
              (!isValidPhoneNumber(props.value || "") ||
                props.value?.length < 6)
                ? "1px solid red"
                : "1px solid black",
          },
        }}
        placeholder={props.inputProps?.label}
        flags={flags}
        id="phone-input"
      />
      {props.value &&
        !isValidPhoneNumber(
          props.value || "",
          setCountryCode(props.value || "")?.country ||
            props.defaultCountry ||
            "IN"
        ) && (
          <Typography
            sx={{ textAlign: "left", paddingLeft: "62px" }}
            variant="caption"
            color={"error"}
          >
            Phone number is not valid{" "}
            {setCountryCode(props.value || "")?.country ||
              props.defaultCountry ||
              "IN"}
          </Typography>
        )}
    </Stack>
  );
}

export interface CustomPhoneInputProps {
  value: string;
  onChange(phone: string): void;
  inputProps: any;
  defaultCountry: CountryCode;
  hideFlags?: boolean;
}
