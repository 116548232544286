import { get, patch, post, put, httpDelete } from "./ApiHelpers";

export function sendOtp(email: string, phone: string) {
  return post(`/send_otp/`, { email, phone });
}
export function validateOtp(
  email: string,
  phone: string,
  otp: string,
  first_name: string,
  designation: string,
  playground_flag: boolean,
  consent: boolean
) {
  return post(`/validate_otp/`, {
    email,
    phone,
    otp,
    first_name,
    designation,
    playground_flag,
    consent,
  }).then((res) => {
    console.log("validate_otp", res);
    if (!res.error) {
      localStorage.setItem("babblebots_auth_data", JSON.stringify(res));
    }
    return res;
  });
}
export const getInterviewTypesForDemoPhoneInterviews = (): Promise<any> => {
  return get(`/company/playground/get_playground_interview_type_list/`);
};

export const fetchCandidateInterview = (candidate_id) => {
  return get(`company/fetch_candidate_detail/${candidate_id}/`);
};

export const updateCandidateDetailsInfo = (
  companyName,
  user_questions_data
) => {
  return put(`/company/playground/update_playground_user_info/`, {
    company_name: companyName,
    user_questions_data,
  });
};

export const getPlaygroundCandidateDetails = () => {
  return get(`/company/playground/get_playground_candidate_details/`);
};

export const InvitePhoneCandidatePlayground = (
  phoneinterviewId: number,
  data
) => {
  // return Promise.resolve({ candidate_id: 24 });
  return post(
    `/company/playground/playground_phone_candidate_invite/${phoneinterviewId}/`,
    data
  );
};

export const currentInterviewStatusPlayground = (candidateId: number) => {
  /*let testjson = {
    data: {
      id: 1086,
      full_name: "pulkit",
      communication_email: "pulkit@babblebots.ai",
      campaign: null,
      company: {
        id: 40,
        name: "BabbleBots Playground",
        description: "Playground for interview bots",
        contract_active: true,
        is_coding_test_enabled: false,
        logo: "https://storage.googleapis.com/babblebots_prod-storage/company/Screenshot_2023-02-08_at_5.45.03_PM-removebg-preview_1.png",
        slug: "playground",
        is_test_company: false,
        region: "In",
        is_whatsapp_enabled: false,
        concised_description: "",
        chargebee_customer_id: "",
        company_url: null,
        linkedin_handler: "",
        company_size: "",
        is_whitelabeled: false,
      },
      cv: null,
      phone_interview_type: {
        phone_interview_type: 23,
        job_title: "Nurse Practitioner",
        questions: [
          {
            question:
              "We are looking for a cardiac nurse. Do you have an ACLS Certificate as a nurse practitioner?",
            answer_type: "Y",
            is_qualifying: true,
            expected_answer: "Yes",
            need_assessment: true,
          },
          {
            question: "Can you briefly outline your primary care experience?",
          },
          {
            question:
              "This position is based out of Miami, Florida. Would that work for you and do you have a reliable mode of transportation?",
            answer_type: "Y",
            expected_answer: "Yes",
            need_assessment: true,
          },
          {
            question:
              "This position offers $40 an hour, does that work for you?",
            answer_type: "Y",
            expected_answer: "Yes",
            need_assessment: true,
          },
          {
            question:
              "Are you comfortable working flexible hours and over the weekends?",
            answer_type: "Y",
            expected_answer: "Yes",
            need_assessment: true,
          },
          {
            question:
              "Are you comfortable going through a background check and an e-verify?",
            answer_type: "Y",
            expected_answer: "Yes",
            need_assessment: true,
          },
          {
            question:
              "That was our last question of the interview. So how was your experience talking to me? Are there any areas that I could improve upon? ",
          },
        ],
      },
      phone_number: "+919414312948",
      state: "C",
      is_active: true,
      created_on: "2024-11-21T05:39:25.786730Z",
      first_invitation_on: "2024-11-21T05:39:25.786743Z",
      last_status_updated_on: "2024-11-21T05:40:25.043442Z",
      selected_on: null,
      completed_on: "2024-11-21T05:40:21.219572Z",
      slots: {},
      interview_score: null,
      language_score: {},
      subjective_assessment_score: null,
      assessment_score: null,
      ai_matching_result: [
        {
          match: true,
          caption: "ACLS Certification",
          question:
            "We are looking for a cardiac nurse. Do you have an ACLS Certificate as a nurse practitioner?",
          expected_answer: "Yes",
          candidate_answer: "Yes.",
        },
        {
          match: false,
          caption: "Location and Transportation",
          question:
            "This position is based out of Miami, Florida. Would that work for you and do you have a reliable mode of transportation?",
          expected_answer: "Yes",
          candidate_answer: null,
        },
        {
          match: false,
          caption: "Salary Expectation",
          question: "This position offers $40 an hour, does that work for you?",
          expected_answer: "Yes",
          candidate_answer: null,
        },
        {
          match: false,
          caption: "Work Schedule Flexibility",
          question:
            "Are you comfortable working flexible hours and over the weekends?",
          expected_answer: "Yes",
          candidate_answer: null,
        },
        {
          match: false,
          caption: "Background Check",
          question:
            "Are you comfortable going through a background check and an e-verify?",
          expected_answer: "Yes",
          candidate_answer: null,
        },
      ],
      cv_ai_matching_result: null,
      interview_summary: null,
      interview_time: null,
      external_application_id: null,
      report: null,
      transcript: [
        {
          role: "bot",
          time: 1732167573885,
          endTime: 1732167576605,
          message: " Hi. Am I speaking to Poket?",
          duration: 1380,
          secondsFromStart: 0,
        },
        {
          role: "user",
          time: 1732167576505,
          endTime: 1732167578995,
          message: "Hello? Yeah. This is Pulkit.",
          duration: 1640,
          secondsFromStart: 2.48,
        },
        {
          role: "bot",
          time: 1732167580105,
          source: "",
          endTime: 1732167587155,
          message:
            "Hi. I'm calling on behalf of BabbleBot's Playground. I have a few questions for you about the nurse practitioner position. Is this a good time to talk?",
          duration: 6980,
          secondsFromStart: 6.08,
        },
        {
          role: "user",
          time: 1732167589064.999,
          endTime: 1732167591165,
          message: "Yes. This is a good time to talk.",
          duration: 2100.0009765625,
          secondsFromStart: 15.039999,
        },
        {
          role: "bot",
          time: 1732167591935,
          source: "",
          endTime: 1732167596735,
          message:
            "Great. I'm Eric, an AI recruiter from Babblebot's Playground. I understand that it may",
          duration: 4010,
          secondsFromStart: 17.91,
        },
        {
          role: "user",
          time: 1732167595855,
          endTime: 1732167597315,
          message: "Because it can be in the interview?",
          duration: 1460,
          secondsFromStart: 21.83,
        },
        {
          role: "bot",
          time: 1732167598175,
          source: "",
          endTime: 1732167602205,
          message:
            "I appreciate your curiosity. However, let's stay focused on the interview. Shall we begin?",
          duration: 3870,
          secondsFromStart: 24.15,
        },
        {
          role: "user",
          time: 1732167607865,
          endTime: 1732167609165,
          message: "Uh, please end the interview.",
          duration: 1300,
          secondsFromStart: 33.84,
        },
        {
          role: "bot",
          time: 1732167610165,
          source: "",
          endTime: 1732167613515,
          message: "No problem. I'll call again later. Have a great day. Bye.",
          duration: 3190,
          secondsFromStart: 36.14,
        },
      ],
      call_recording_url:
        "https://storage.googleapis.com/babblebots_prod-storage/recording_13517.wav",
      playground_user: {
        designation: "frontend dev",
        email: "pulkit@babblebots.ai",
      },
    },
    waiting: false,
    phone_interview_state: "D",
  };
  return Promise.resolve(testjson);*/
  return get(
    `/company/playground/get_playground_phone_interview_status/${candidateId}/`
  );
};
export function getAuthToken() {
  let currentUser = getCurrentUserDetails();
  console.log("this is current user", currentUser);
  const hasValidToken =
    currentUser?.token &&
    new Date(currentUser.expiry).getTime() >
      new Date(new Date().toUTCString()).getTime();
  let parsedAuthData;
  try {
    parsedAuthData = hasValidToken
      ? JSON.parse(localStorage.getItem("babblebots_auth_data") || "{}")?.token
      : {};
  } catch (e) {
    console.error("Error parsing babblebots_auth_data from localStorage:", e);
    parsedAuthData = {};
  }
  return hasValidToken ? parsedAuthData : null;
  /*const authDetails = localStorage.getItem("babblebots_auth_data");
  if (authDetails) {
    return JSON.parse(authDetails).token;
  }
  return "";*/
}
export function getCurrentUserDetails() {
  const authData = localStorage.getItem("babblebots_auth_data");

  // If it's JSON, parse it, otherwise return it directly
  try {
    return authData ? JSON.parse(authData) : null; // In case it's valid JSON
  } catch (e) {
    console.warn(
      "Stored auth data is not a valid JSON, returning as string",
      e
    );
    return authData; // Fallback to return raw string (likely a token)
  }
}
