import React, { useEffect, useState, useRef } from "react";
import {
  currentInterviewStatusPlayground,
  fetchCandidateInterview,
  updateCandidateDetailsInfo,
} from "../PhoneInterviewApi";
import {
  Button,
  Stack,
  Typography,
  TextField,
  Link,
  Dialog,
  useTheme,
  Card,
  CardContent,
  Radio,
  Chip,
  DialogContent,
  Paper,
  Tooltip,
  Alert,
  AlertTitle,
  Box,
  Popover,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Checkbox,
} from "@mui/material";
import "./CandidateReport.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import ReactAudioPlayer from "react-audio-player";
import { CancelOutlined } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RingVolumeIcon from "@mui/icons-material/RingVolume";
import CallIcon from "@mui/icons-material/Call";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { LoadingButton } from "@mui/lab";

const TEST_DATA = {
  id: "*****",
  full_name: "*****",
  communication_email: "*****",
  campaign: null,
  company: {
    id: "*****",
    name: "*****",
    description: "*****",
    contract_active: true,
    is_coding_test_enabled: false,
    logo: "*****",
    slug: "*****",
    is_test_company: false,
    region: "*****",
    is_whatsapp_enabled: "*****",
    concised_description: "*****",
    chargebee_customer_id: "*****",
    company_url: null,
    linkedin_handler: "*****",
    company_size: "*****",
    is_whitelabeled: "*****",
  },
  cv: null,
  phone_interview_type: {
    phone_interview_type: "*****",
    job_title: "*****",
    questions: [
      {
        question: "*****",
        answer_type: "*****",
        is_qualifying: "*****",
        expected_answer: "*****",
        need_assessment: "*****",
      },
      {
        question: "*****",
      },
      {
        question: "*****",
        answer_type: "*****",
        expected_answer: "*****",
        need_assessment: "*****",
      },
      {
        question: "*****",
        answer_type: "*****",
        expected_answer: "*****",
        need_assessment: "*****",
      },
      {
        question: "*****",
        answer_type: "*****",
        expected_answer: "*****",
        need_assessment: "*****",
      },
      {
        question: "*****",
        answer_type: "*****",
        expected_answer: "*****",
        need_assessment: "*****",
      },
      {
        question: "*****",
      },
    ],
  },
  phone_number: "*****",
  state: "*****",
  is_active: "*****",
  created_on: "*****",
  first_invitation_on: "*****",
  last_status_updated_on: "*****",
  selected_on: null,
  completed_on: "*****",
  slots: "*****",
  interview_score: null,
  language_score: "*****",
  subjective_assessment_score: null,
  assessment_score: null,
  ai_matching_result: null,
  cv_ai_matching_result: null,
  interview_summary: null,
  interview_time: null,
  external_application_id: null,
  report: null,
  transcript: [
    {
      role: "bot",
      time: "*****",
      endTime: "*****",
      message: "*****",
      duration: "*****",
      secondsFromStart: "*****",
    },
    {
      role: "user",
      time: "*****",
      endTime: "*****",
      message: "*****",
      duration: "*****",
      secondsFromStart: "*****",
    },
    {
      role: "bot",
      time: "*****",
      source: "*****",
      endTime: "*****",
      message: "*****",
      duration: "*****",
      secondsFromStart: "*****",
    },
    {
      role: "user",
      time: "*****",
      endTime: "*****",
      message: "*****",
      duration: "*****",
      secondsFromStart: "*****",
    },
    {
      role: "bot",
      time: "*****",
      source: "*****",
      endTime: "*****",
      message: "*****",
      duration: "*****",
      secondsFromStart: "*****",
    },
    {
      role: "user",
      time: "*****",
      endTime: "*****",
      message: "*****",
      duration: "*****",
      secondsFromStart: "*****",
    },
    {
      role: "bot",
      time: "*****",
      source: "*****",
      endTime: "*****",
      message: "*****",
      duration: "*****",
      secondsFromStart: "*****",
    },
    {
      role: "user",
      time: "*****",
      endTime: "*****",
      message: "*****",
      duration: "*****",
      secondsFromStart: "*****",
    },
    {
      role: "bot",
      time: "*****",
      source: "*****",
      endTime: "*****",
      message: "*****",
      duration: "*****",
      secondsFromStart: "*****",
    },
  ],
  call_recording_url: "*****",
  playground_user: {
    designation: "*****",
    email: "*****",
  },
};

type FormValuesProps = {
  companyName: string;
  motivation: string;
  organizationBenefits: string[];
};
function CandidateReport({
  fetchStatus,
  setFetchStatus,
  candidateId,
  selectedInterviewRegion,
}) {
  const [interviewwaitingStatus, setInterviewWaitingStatus] = useState(true);
  const [status, setStatus] = useState("P");
  const waitingTimeRef = useRef(0);
  const theme = useTheme();
  const [candidateDetails, setCandidateDetails] = useState<any>(null);
  const [currentAssessment, setCurrentAssessment] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<any>();
  const [showImportantNote, setShowImportantNote] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(status);
  const [showInterviewReport, setShowInterviewReport] = useState(false);
  const [interviewCompleteDetails, setInterviewCompleteDetails] =
    useState<any>();
  const timerRef = useRef(null);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    setValue,
    watch,
  } = useForm<FormValuesProps>({
    mode: "onChange",
    //resolver:  yupResolver(FormSchema),
    resolver: undefined,
    defaultValues: {
      companyName: "",
      motivation: "",
      organizationBenefits: [],
    },
  });

  let companyName = watch("companyName");
  let motivation = watch("motivation");
  let organizationBenefits = watch("organizationBenefits");
  let isFormComplete =
    companyName && motivation && organizationBenefits?.length;
  useEffect(() => {
    let intervalId;

    if (fetchStatus && interviewwaitingStatus) {
      intervalId = setInterval(() => {
        currentInterviewStatusPlayground(candidateId).then((res) => {
          setInterviewCompleteDetails(res);
          setStatus(res?.phone_interview_state);
          if (res?.phone_interview_state == "E") {
            waitingTimeRef.current = waitingTimeRef.current + 1;
          }
          //&& res?.data?.transcript == null
          if (waitingTimeRef.current >= 120) {
            setInterviewWaitingStatus(false);
            setStatus("Done");
            clearInterval(intervalId);
          }
          if (res?.waiting == false) {
            setInterviewWaitingStatus(false);
            setStatus("Done");
            setCandidateDetails(res?.data);
            clearInterval(intervalId);
          }
        });
      }, 1000);
      //Changed it to 3000000 from 1000 for testing
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [fetchStatus]);

  function getStatusElement(currentstatus, showImportantNote) {
    let text;
    let icon;
    let customcolor;
    let importantnotetext;

    switch (currentstatus) {
      case "P":
        {
          text =
            "Initiating the call, wait for at least 5 minutes before reloading the page";
          icon = <CallIcon />;
          customcolor = "white";
          importantnotetext =
            "Please Wait for at least 5 minutes before initiating the call again";
        }
        break;
      case "S":
        {
          text =
            "Interview in progress, wait after ending the call because it could take upto 2 mins to see the report";
          icon = <RingVolumeIcon />;
          customcolor = "white";
          importantnotetext =
            "Please wait after ending the call, It could take upto 2 mins to see the report";
        }
        break;
      case "E":
        {
          text =
            "Fetching the report, Please wait for upto 2 minutes to see the report";
          icon = <HourglassEmptyIcon />;
          customcolor = "white";
          importantnotetext = "We are fetching the report for you";
        }
        break;
      default: {
        text =
          "Initiating the call, wait for at least 5 minutes before reloading the page";
        icon = <CallIcon />;
        customcolor = "white";
      }
    }
    return (
      <Stack
        sx={{ display: "flex", flexDirection: "column", maxWidth: "370px" }}
      >
        <Typography
          variant="body1"
          color={customcolor}
          align="center"
          style={{
            fontWeight: "500",
            animation: "pulse 1s infinite ease-in-out",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography>{icon}</Typography>
          <Typography>{text}</Typography>
        </Typography>
        {showImportantNote && (
          <Stack sx={{ color: "white", fontWeight: "800", marginTop: "20px" }}>
            {importantnotetext}
          </Stack>
        )}
      </Stack>
    );
  }

  useEffect(() => {
    if (status !== currentStatus) {
      setCurrentStatus(status);
      setShowImportantNote(false);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      return;
    }
    //The text will show up after 10 seconds
    timerRef.current = setTimeout(() => {
      setShowImportantNote(false); //Changed it to false for testing
    }, 10000);
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [status, currentStatus]);
  return (
    <div>
      <Dialog
        open={fetchStatus}
        onClose={() => setFetchStatus(false)}
        sx={{
          "& .MuiPaper-root.MuiDialog-paper": {
            width: status == "Done" ? "720px" : "fit-content",
            maxWidth: "95vw",
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: "16px",
            // padding: "24px",
          },
        }}
      >
        <DialogContent>
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Stack alignItems="center" justifyContent="center">
              {status !== "Done" && getStatusElement(status, showImportantNote)}
            </Stack>
            {status === "Done" ? (
              <>
                <Stack spacing={2} sx={{ width: "100%", position: "relative" }}>
                  {/* Candidate Details Paper */}
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6"> Interview Report</Typography>
                    <Paper
                      sx={{
                        padding: theme.spacing(1.5),
                        backgroundColor: theme.palette.background.paper,
                        marginTop: "20px",
                        width: "96%",
                      }}
                    >
                      <Stack
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          {/* <Typography variant="subtitle1" display="block">
                          {" "}
                          Thanks for taking the time to talk to{" "}
                          {selectedInterviewRegion == "India"
                            ? "Tushar"
                            : "Eric"}
                          !
                        </Typography> */}
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              padding: "10px 0px",
                            }}
                          >
                            <Typography sx={{ fontSize: "18px" }}>
                              Thanks for taking the time to talk to{" "}
                              {selectedInterviewRegion == "India"
                                ? "Rohan"
                                : "Eric"}
                              !
                              <br />
                              Let's chat to find out how we can help you in
                              hiring
                            </Typography>
                            <Button
                              variant="contained"
                              onClick={() => {
                                window.open(
                                  "https://calendly.com/sales-babblebots/30min?month=2024-11",
                                  "_blank"
                                );
                              }}
                              sx={{
                                alignSelf: "center",
                                minWidth: "112px",
                                // minHeight: "35px",
                                display: "inline",
                              }}
                            >
                              Book a Demo
                            </Button>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Paper>
                  </Stack>
                  {/*<Paper
                  sx={{
                    padding: theme.spacing(1.5),
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Stack spacing={1} alignItems="center">
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      color={theme.palette.text.primary}
                    >
                      {candidateDetails.name}
                    </Typography>
                    {!!candidateDetails.completed_on && (
                      <Stack sx={{ fontSize: "14px" }}>
                        {`Completed on ${moment(
                          new Date(candidateDetails.completed_on)
                        ).format("Do MMM, LT ")}`}
                      </Stack>
                    )}
                    <Stack direction="row" spacing={1}>
                      {/* Email Button 
                      {!!candidateDetails.communication_email && (
                        <Tooltip
                          title={`${candidateDetails.communication_email}\n (click to copy)`}
                          placement="top-start"
                        >
                          <Button
                            variant="text"
                            disableElevation
                            size="small"
                            sx={{
                              justifyContent: "flex-start",
                              paddingLeft: theme.spacing(0.25),
                            }}
                            startIcon={<MailOutlineIcon />}
                            onClick={() =>
                              navigator.clipboard.writeText(
                                candidateDetails.communication_email
                              )
                            }
                          >
                            Email
                          </Button>
                        </Tooltip>
                      )}
                      {/* Phone Button 
                      {!!candidateDetails.phone_number && (
                        <Tooltip
                          title={`${candidateDetails.phone_number}\n (click to copy)`}
                          placement="top-start"
                        >
                          <Button
                            variant="text"
                            disableElevation
                            size="small"
                            sx={{ justifyContent: "flex-start" }}
                            startIcon={<WhatsAppIcon />}
                            onClick={() =>
                              navigator.clipboard.writeText(
                                candidateDetails.phone_number
                              )
                            }
                          >
                            Phone
                          </Button>
                        </Tooltip>
                      )}
                    </Stack>
                  </Stack>
                </Paper>*/}

                  {/* Assessment Paper */}
                  {(showInterviewReport ? { ...candidateDetails } : TEST_DATA)
                    ?.ai_matching_result?.length > 0 && (
                    <Paper
                      sx={{
                        padding: theme.spacing(1.5),
                        backgroundColor: theme.palette.background.paper,
                      }}
                    >
                      <Stack spacing={1}>
                        <Typography
                          variant="h6"
                          color={theme.palette.text.primary}
                        >
                          Interview Assessment
                        </Typography>
                        <Stack
                          direction="row"
                          flexWrap="wrap"
                          sx={{
                            justifyContent: "flex-start",
                            padding: 0,
                          }}
                        >
                          {(showInterviewReport
                            ? { ...candidateDetails }
                            : TEST_DATA
                          ).ai_matching_result?.map((assessment, index) => (
                            <Alert
                              key={index}
                              icon={
                                assessment.match ? null : <CancelOutlined />
                              }
                              severity={assessment.match ? "success" : "error"}
                              sx={{
                                flex: 1,
                                m: theme.spacing(0.5),
                                minWidth: {
                                  xs: "calc(90%-24px)",
                                  sm: "calc(45% - 24px)",
                                },
                                maxWidth: {
                                  xs: "calc(90%-24px)",
                                  sm: "calc(45% - 24px)",
                                },
                              }}
                              className="ai-matching-box"
                            >
                              <AlertTitle sx={{ ml: theme.spacing(0.75) }}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <span>{assessment.caption}</span>
                                </Stack>
                              </AlertTitle>
                              <Box>
                                <Button
                                  size="small"
                                  sx={{
                                    padding: "5px 10px",
                                    width: "fit-content",
                                  }}
                                  onClick={(event) => {
                                    setCurrentAssessment(assessment);
                                    setAnchorEl(
                                      anchorEl ? null : event.currentTarget
                                    );
                                  }}
                                >
                                  Show Response
                                </Button>
                              </Box>
                            </Alert>
                          ))}
                          <Popover
                            sx={{
                              zIndex: 9999,
                              "& .MuiPopover-paper": {
                                padding: theme.spacing(1.5),
                                maxWidth: "480px",
                              },
                            }}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <Stack spacing={1}>
                              <Typography>
                                <b>Expected Answer: </b>{" "}
                                {currentAssessment?.expected_answer}
                              </Typography>
                              <Typography>
                                <b>Candidate Answer: </b>{" "}
                                {currentAssessment?.candidate_answer}
                              </Typography>
                            </Stack>
                          </Popover>
                        </Stack>
                      </Stack>
                    </Paper>
                  )}
                  {/* Interview Recording and Transcript */}
                  <Paper
                    sx={{
                      padding: theme.spacing(1.5),
                      backgroundColor: theme.palette.background.paper,
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        variant="h6"
                        color={theme.palette.text.primary}
                      >
                        Interview Recording and Transcript
                      </Typography>
                      <Stack spacing={1}>
                        <ReactAudioPlayer
                          style={{
                            width: "100%",
                            marginBlock: theme.spacing(2),
                          }}
                          src={
                            (showInterviewReport
                              ? { ...candidateDetails }
                              : TEST_DATA
                            )?.call_recording_url
                          }
                          controls
                        />
                        {(candidateDetails || TEST_DATA)?.transcript?.map(
                          (interaction, i) => (
                            <Stack
                              key={interaction.message + i}
                              direction="column"
                              sx={{
                                padding: "8px",
                                borderRadius: "2px",
                                width: "fit-content",
                                backgroundColor:
                                  interaction.role === "bot"
                                    ? theme.palette.grey[300]
                                    : "#a2c1f3",
                              }}
                            >
                              <Typography
                                variant="overline"
                                sx={{ color: "black" }}
                                lineHeight={1.8}
                                fontWeight="600"
                              >
                                {interaction.role === "bot"
                                  ? selectedInterviewRegion == "India"
                                    ? "Rohan"
                                    : "Eric"
                                  : "Candidate"}
                              </Typography>
                              <Typography variant="body2" color="black">
                                {interaction.message}
                              </Typography>
                            </Stack>
                          )
                        )}
                      </Stack>
                    </Stack>
                  </Paper>
                  {/* CTA */}
                </Stack>
                {!showInterviewReport && (
                  <Stack
                    sx={{
                      backdropFilter: "blur(10px)",
                      zIndex: "100",
                      position: "absolute",
                      top: "0%",
                      left: "0%",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Stack
                      sx={{
                        margin: "auto",
                        width: { xs: "90%", sm: "70%", md: "50%" },
                        backgroundColor: "#121212",
                        borderRadius: "10px",
                        padding: "20px",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        gap: 3,
                      }}
                    >
                      {/* Header Section */}
                      <Stack alignItems="center" spacing={1}>
                        <Typography
                          variant="h5"
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Explore AI Interview Insights
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#aaa",
                            textAlign: "center",
                            maxWidth: "80%",
                          }}
                        >
                          Fill in your details and share your thoughts on this
                          AI-powered interview process.
                        </Typography>
                      </Stack>

                      {/* Name Input */}
                      <Controller
                        name="companyName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Company Name"
                            onChange={(e) => {
                              setValue("companyName", e.target.value);
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "#1e1e1e",
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#12a797 !important",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#12a797",
                                    boxShadow:
                                      "0 0 8px rgba(18, 167, 151, 0.6)",
                                  },
                              },
                              "& .MuiInputBase-input": {
                                color: "#fff",
                                padding: "12px 15px",
                              },
                              "& .MuiInputLabel-root": {
                                color: "#aaa",
                              },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: "#12a797",
                              },
                            }}
                          />
                        )}
                      />

                      {/* Dropdown Question 1 */}
                      <Stack spacing={2}>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          What motivated you to explore such an interview
                          process?
                        </Typography>
                        <Controller
                          name="motivation"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              select
                              fullWidth
                              value={field.value || ""}
                              onChange={(e) => {
                                field.onChange(e);
                                setValue("motivation", e.target.value);
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor: "#1e1e1e",
                                  color: "white",
                                  borderRadius: "8px",
                                },
                                "& .MuiSelect-icon": {
                                  color: "white",
                                },
                              }}
                            >
                              {[
                                "Explore how AI can streamline our hiring process.",
                                "Experience how AI evaluates and converses with candidates.",
                                "Just out of curiosity about AI-driven interviews.",
                                "To compare it with our existing interview process.",
                                "Others",
                              ].map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Stack>

                      {/* Dropdown Question 2 */}
                      <Stack spacing={2}>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          How do you see this technology benefiting your
                          organization?
                        </Typography>
                        <Controller
                          name="organizationBenefits"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              select
                              fullWidth
                              value={field.value || []}
                              onChange={(e) => {
                                const selectedValues = e.target.value;
                                field.onChange(selectedValues);
                                console.log("these are selected");
                                // setValue(
                                //   "organizationBenefits",
                                //   selectedValues.split(", ")
                                // );
                              }}
                              SelectProps={{
                                multiple: true, // Enable multi-select
                                renderValue: (selected) =>
                                  organizationBenefits?.length > 0
                                    ? organizationBenefits.join(", ")
                                    : "Select options",
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor: "#1e1e1e",
                                  color: "white",
                                  borderRadius: "8px",
                                },
                                "& .MuiSelect-icon": {
                                  color: "white",
                                },
                              }}
                            >
                              {[
                                "Screening and shortlisting candidates faster",
                                "Standardizing the interview process across roles and teams",
                                "Enhancing the candidate experience",
                                "Reducing my recruiter's workload",
                                "Automating routine interview tasks to focus on high-value activities",
                                "Others",
                              ].map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  <Stack
                                    direction={"row"}
                                    spacing={1}
                                    alignItems={"center"}
                                  >
                                    <Checkbox
                                      checked={organizationBenefits?.includes(
                                        option
                                      )}
                                    />
                                    <Typography>{option}</Typography>
                                  </Stack>
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Stack>

                      {/* Action Button */}
                      <LoadingButton
                        loading={showInterviewReport && !candidateDetails}
                        onClick={() => {
                          fetchCandidateInterview(candidateId).then((res) => {
                            currentInterviewStatusPlayground(candidateId).then(
                              (mainres) => {
                                setInterviewCompleteDetails(mainres);
                                setCandidateDetails(mainres.data);
                              }
                            );
                          });

                          updateCandidateDetailsInfo(companyName, [
                            motivation,
                            organizationBenefits,
                          ]);

                          setShowInterviewReport(true);
                        }}
                        fullWidth
                        disabled={!isFormComplete}
                        sx={{
                          backgroundColor: isFormComplete ? "#12a797" : "#555",
                          color: "white",
                          padding: "10px 20px",
                          borderRadius: "25px",
                          textTransform: "capitalize",
                          fontWeight: "bold",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                          "&:hover": {
                            backgroundColor: isFormComplete
                              ? "#0e805e"
                              : "#555",
                            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)",
                          },
                        }}
                      >
                        Check Interview Report
                      </LoadingButton>
                    </Stack>
                  </Stack>
                )}
              </>
            ) : null}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CandidateReport;
