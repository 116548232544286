import { Stack, Typography, useTheme, Box, Button } from "@mui/material";
import React from "react";
// import { theme } from "../theme";
import logoimage from "../assets/logo_image.png";
function Header({ setIsOpen }) {
  const theme = useTheme();
  return (
    <Stack
      alignItems={"center"}
      direction={"row"}
      justifyContent={"space-between"}
      spacing={1}
      sx={{
        backgroundColor: "rgba(18,18,18,0.7)",
        padding: "24px 12px 0px 24px",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: "200",
        backdropFilter: "blur(8px)",
      }}
    >
      <img src={logoimage} height={"48px"} alt=""></img>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{ flexGrow: 1, textAlign: "right", padding: "10px 20px 0px 0px" }}
      >
        <Button
          size="medium"
          style={{
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.removeItem("babblebots_auth_data");
            setIsOpen(true);
          }}
        >
          Logout
        </Button>
      </Box>
    </Stack>
  );
}

export default Header;
